// BASE URL
export const BASE_URL =
	"https://ess-api-django-a6fc2c26f42e.herokuapp.com/api/";
// export const BASE_URL = "http://127.0.0.1:8000/api/";

export const LOGIN = "login";
export const HELLO = "hello";
export const REFRESH = "token/refresh/";
export const TEAMCHART = "timesheet/organization_chart/";
export const APPLYLEAVE = "lms/apply_leave/";
export const COMPENSATION = "lms/compensation/";
export const LMSDASHBOARD = "lms/dashboard/";
export const LMSLATESTLEAVE = "lms/latest_appications/";
export const HOLIDAYCALENDAR = "lms/holiday_calender/";
export const TIMESHEETCHART = "timesheet/user_dashboard_charts/";
export const LEAVEPOLICY = "hr_policy";
export const TIMESHEET = "timesheet/usertimesheet/";
export const TIMESHEETEDIT = "timesheet/edit_timesheet/";
export const MYPROFILE = "my_profile";
export const LEAVEDETAILS = "lms/leave_details/";
export const LEAVEHISTORY = "lms/user_leave_history/";
export const REJECTEDAPPLICATIONS = "lms/rejected_applications/";
export const USERHOLIDAYDETAILS = "lms/user_holiday_details/";
export const CREATEUSER = "create/";
export const MODIFIEDUSER = "modified_user/";
export const EDITUSER = "edit_user/?id=";
export const EDITUSERPROFILE = "profile_pic/?id=";
export const NOTIFICATION = "timesheet/timesheet_notification/";
export const DELETEUSER = "delete_user/";
export const REDEEM = "lms/redeem/";
export const NOTIFYALERT = "timesheet/check_for_new_msg/";
export const PAYROLLUSERDATA = "payroll/users_dashboard/";
export const FORMSIXTEENUPDATE = "payroll/form16_update/";
export const HRTIMESHEETVIEW = "timesheet/hr_timesheet_viewer/";
export const HRUSERVIEW = "timesheet/hr_user_view/";
export const PAYPAYSLIPGENERATION = "payroll/pay_slip_gen/";
export const TIMESHEETEXCEL = "timesheet/excel/";
export const LEAVEPOLICYFILE = "hr_update/";
export const HRPOLICYFILE = "hr_policy_update/";
export const TRAININGFILE = "upload_training/";
export const DELETEFILE = "training/delete_file/";
export const PAYROLLPAYSLIPHR = "payroll/payslip_hr/";
export const PAYROLLPAYSLIPUAPDATE = "payroll/pay_slip_update/";
export const ADDHOLIDAY = "createholidays/";
export const MODIFYHOLIDAY = "modifyholidays/";
export const TRAINING = "training/";
export const TRAININGPDF = "training_download/";
export const PAYROLLPAYSLIP = "payroll/payslip/";
export const HRPOLICYPAYROLL = "policy_hr";
export const HELPDESK = "help_desk/";
export const HELPDESKTICKET = "ticket/";
export const OPENTICKETHR = "ticket_viewer/";
export const OPENTICKETHRIMAGE = "ticket_attachement/";
export const FORMSIXTEENPAYROLLPDF = "payroll/form-16/";
export const NOTIFICATIONALERTDATA = "notification_admin/";
export const MYDETAILSDATA = "my_details/";
export const USERSKILLS = "myskills/";
export const USERSKILLTABLE = "user_skill_tablet/";
export const PRIMARYSKILLSADMIN = "primary_my_skill_admin/";
export const SECONDARYSKILLSADMIN = "secondary_my_skill_admin/";
export const CLOSEDTICKET = "tickets_close/";
export const FORGOTPASSWORD = "forget_passoword/";
export const CHANGEPASSWORD = "change_passoword/";
export const ANNOUNCEMENT = "anouncement_notify/";
export const HOLIDAYCALENDARLEAVES = "lms/apply_leave_hol/";
export const EMPREPORTTO = "lms/emp-dashboard/";
export const DELETELEAVEREQUEST = "lms/delete_request/";
export const TECHSUPPORTSEEN = "tech_dashboard/";
export const TECHSUPPORTSHARE = "technical_support/";
export const WFHWORKMODE = "timesheet/apply_workmode_leave/";
export const LATESTWORKMODE = "timesheet/latest_workmode_leave/";
export const WFHDETAILS = "timesheet/work_from_home_details/";
export const DELETEWFH = "timesheet/delete_wfh_details/";
export const WFHHISTORY = "timesheet/user_wfh_history/";
export const EmpDetailsForMangInfo = "user_bio/";
