import React, { useContext, useEffect, useState } from "react";
import ApiCall from "../utils/ApiCall";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { CardData } from "../data";
import { Link, useHistory } from "react-router-dom";
import AuthContext from "../contexts/AuthContext";
import "../css/homePage.css";
import { ANNOUNCEMENT } from "../Constants";
import { MdNotificationsActive } from "react-icons/md";
import "../css/notification.css";
import { Popup } from "../components";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

SwiperCore.use([Navigation, Autoplay]);

const HomePage = () => {
	const { user, authTokens } = useContext(AuthContext);
	const history = useHistory();
	const [announcement, setAnnouncement] = useState([]);
	const [notifyPopup, setNotifyPopup] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		fetchData();
		const delay = setTimeout(() => {
			setIsLoading(false);
		}, 1000);
		return () => clearTimeout(delay);
	}, []);

	const fetchData = async () => {
		try {
			const res = await ApiCall(ANNOUNCEMENT, "GET", authTokens, null);
			//console.log("announcement", res.data);
			setAnnouncement(res.data);
		} catch (error) {
			console.log("Error while fetching data", error);
		}
	};

	const handleNotification = () => {
		setNotifyPopup(announcement);
	};

	if (!user) {
		return history.push("/login");
	}
	// console.log("user", user);

	const filteredCards = CardData.filter((card) => {
		if (user.user_role === "MANAGER") {
			return (
				card.title === "Payroll" ||
				card.title === "LMS" ||
				// card.title === "Our Organization" ||
				card.title === "My Team" ||
				card.title === "My Details" ||
				card.title === "Timesheet" ||
				card.title === "Employee Details" ||
				card.title === "Training" ||
				card.title === "Help Desk"
				// card.title === "Conference Room"
			);
		} else if (
			user.user_role === "EMPLOYEE" ||
			user.user_role === "CONSULTANT"
		) {
			return (
				card.title === "Payroll" ||
				// card.title === "Our Organization" ||
				card.title === "LMS" ||
				card.title === "My Team" ||
				card.title === "My Details" ||
				card.title === "Timesheet" ||
				card.title === "Training" ||
				card.title === "Help Desk"
				// card.title === "Conference Room"
			);
		} else if (user.user_role === "TRAINEE") {
			return (
				card.title === "Payroll" ||
				// card.title === "Our Organization" ||
				card.title === "LMS" ||
				// card.title === "My Team" ||
				card.title === "My Details" ||
				card.title === "Timesheet" ||
				card.title === "Training" ||
				card.title === "Help Desk"
				// card.title === "Conference Room"
			);
		} else if (user.user_role === "ADMIN") {
			return (
				card.title === "LMS" ||
				// card.title === "Our Organization" ||
				card.title === "My Details" ||
				card.title === "Admin Portal" ||
				card.title === "Help Desk"
				// card.title === "Conference Room"
			);
		} else if (user.user_role === "CEO") {
			return (
				card.title === "Payroll" ||
				card.title === "LMS" ||
				// card.title === "Our Organization" ||
				card.title === "My Team" ||
				card.title === "My Details" ||
				card.title === "Help Desk"
				// card.title === "Conference Room"
			);
		} else if (user.user_role === "INTERN") {
			return (
				card.title === "LMS" ||
				// card.title === "Our Organization" ||
				card.title === "My Team" ||
				card.title === "My Details" ||
				card.title === "Timesheet" ||
				card.title === "Training" ||
				card.title === "Help Desk"
				// card.title === "Conference Room"
			);
		} else if (user.user_role === "HR") {
			return (
				card.title === "Payroll" ||
				card.title === "LMS" ||
				// card.title === "Our Organization" ||
				card.title === "Timesheet" ||
				card.title === "My Details" ||
				card.title === "Help Desk"
				// card.title === "Conference Room"
			);
		} else {
			return true;
		}
	});
	return (
		<div className="bg-dashboardBg h-screen pb-4 flex flex-col">
			<div className="w-full flex flex-col justify-center items-center mb-10">
				<h1 className="md:text-4xl text-lg text-center flex justify-center pt-20  text-white intro-x">
					Welcome To ESS Employee Login
				</h1>
				{announcement && announcement.announcement === true ? (
					<h3
						onClick={handleNotification}
						className="texet-center notification flex justify-center items-center mt-6 gap-2 cursor-pointer"
					>
						<MdNotificationsActive /> <span>New Announcement</span>
					</h3>
				) : null}
			</div>
			<div className="flex justify-center mt-4 px-8 intro-x">
				<Swiper
					slidesPerView={1}
					spaceBetween={0}
					navigation
					autoplay={{ delay: 4000 }}
					breakpoints={{
						640: {
							slidesPerView: 2,
							spaceBetween: 1,
						},
						768: {
							slidesPerView: 2,
							spaceBetween: 1,
						},
						1024: {
							slidesPerView: 3,
							spaceBetween: 0,
						},
					}}
				>
					{filteredCards.map((item, index) => (
						<SwiperSlide key={index}>
							<div className="flex flex-wrap justify-center md:h-[350px] ms-5 me-5 mb-4">
								<div className="max-w-sm pt-3 mx-2 bg-cardsBg rounded-2xl shadow">
									{isLoading ? (
										<Skeleton
											baseColor="#808080"
											className="md:h-20 md:w-20 h-16 w-16 rounded-full mx-auto block mt-8"
										/>
									) : (
										<Link to={item.url}>
											<img
												className="rounded-t-lg h-16 mx-auto block mt-8 transition-all ease-in-out"
												src={item.img}
												alt={item.title}
											/>
										</Link>
									)}
									<div className="p-5">
										<Link to={item.url}>
											<h5 className="text-xl  flex justify-center font-bold tracking-tight text-black">
												{item.title}
											</h5>
										</Link>
										<p className="my-4 text-md text-black text-center">
											{item.description}
										</p>
										<div className="flex justify-center mt-6">
											<Link
												to={item.url}
												className="flex w-32 items-center justify-center px-3 py-2 text-sm font-medium text-center text-white bg-buttonBg hover:bg-buttonBg-hover rounded-md focus:ring-4 focus:outline-none shadow-md"
											>
												{item.button}
											</Link>
										</div>
									</div>
								</div>
							</div>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
			{notifyPopup && (
				<Popup
					title={`${
						notifyPopup.notifications.length > 1
							? notifyPopup.notifications.length
							: "New"
					} Announcement${notifyPopup.notifications.length > 1 ? "s" : ""}`}
					trigger={notifyPopup}
					setTrigger={setNotifyPopup}
					popupstyle="md:w-2/5 w-full"
				>
					{notifyPopup.notifications &&
						notifyPopup.notifications.map((item, index) => (
							<div key={index} className="w-full p-2">
								<h3 className="text-center font-semibold text-md capitalize underline">
									{item.title}
								</h3>
								<p className="p-2 py-4 font-semibold break-words">
									{item.description}
								</p>
							</div>
						))}
				</Popup>
			)}
		</div>
	);
};

export default HomePage;
