import {
	MdSpaceDashboard,
	MdPostAdd,
	MdNotificationsActive,
	MdOutlineLibraryBooks,
	MdStackedBarChart,
	MdOutlineAccountCircle,
	MdOutlineCalendarToday,
	MdOutlineMonetizationOn,
	MdOutlineCardGiftcard,
	MdOutlineEvent,
	MdOutlinePolicy,
	MdOutlineHome,
	MdPerson,
	MdHolidayVillage,
	MdAnnouncement,
	MdPolicy,
	MdInsertDriveFile,
	MdHome,
	MdAirplaneTicket,
	MdClosedCaptionDisabled,
	MdCancel,
	MdOutlineDelete,
	MdOutlinePsychology,
	MdCancelPresentation,
	MdOutlinePersonOutline,
	MdOutlineMenuBook,
	MdBuild,
	MdOutlineAddHomeWork,
	MdNewspaper,
} from "react-icons/md";
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import {
	FaHandsHelping,
	FaFilePdf,
	FaWpforms,
	FaHistory,
	FaHeadset,
} from "react-icons/fa";
import { AiOutlineEdit } from "react-icons/ai";
import { RxUpdate } from "react-icons/rx";
import { TbBrandCashapp } from "react-icons/tb";
import { AiOutlineForm } from "react-icons/ai";
import { BsCashCoin } from "react-icons/bs";
import Payroll from "../src/images/Payroll.png";
import LMS from "../src/images/LMS.png";
import timesheet from "../src/images/timesheet.png";
import team from "../src/images/team.png";
import details from "../src/images/details.png";
import support from "../src/images/support.png";
import training from "../src/images/training.png";
import admin from "../src/images/admin2.png";
import Manager from "../src/images/manager.png";
import Trainer from "../src/images/trainer.png";
import Men from "../src/images/men.jpg";
import Women from "../src/images/women.jpg";
import EmpDetailInfo from "../src/images/empdetailinfo.png";
import { NotificationTimesheet } from "./components";

export const TimeSheetItems = [
	{
		title: "Dashboard",
		url: "timesheet/dashboard",
		icon: <MdSpaceDashboard />,
	},
	{
		title: "TimeSheet",
		url: "timesheet/timesheets",
		icon: <MdPostAdd />,
	},
	{
		title: "Notification",
		url: "timesheet/notification",
		icon: <NotificationTimesheet />,
	},
	{
		title: "Employee Self Service",
		url: "",
		icon: <MdHome size={18} />,
	},
];

export const TimeSheetItemsForManager = [
	{
		title: "Dashboard",
		url: "timesheet/dashboard",
		icon: <MdSpaceDashboard />,
	},
	{
		title: "TimeSheet",
		url: "timesheet/timesheets",
		icon: <MdPostAdd />,
	},
	{
		title: "Notification",
		url: "timesheet/notification",
		icon: <NotificationTimesheet />,
	},

	{
		title: "Employee Timesheet",
		url: "timesheet/employee-dashboard",
		icon: <MdPerson />,
	},
	// {
	// 	title: "Employee Project",
	// 	url: "timesheet/project-user",
	// 	icon: <MdPerson />,
	// },
	{
		title: "Employee Self Service",
		url: "",
		icon: <MdHome size={18} />,
	},
];

export const TimeSheetItemsForHr = [
	{
		title: "Employee Timesheet",
		url: "timesheet/dashboard",
		icon: <MdPerson />,
	},

	{
		title: "Employee Self Service",
		url: "",
		icon: <MdOutlineHome size={20} />,
	},
];

export const TrainingItems = [
	{
		title: "Training",
		url: "training",
		icon: <FaFilePdf />,
	},

	{
		title: "Employee Self Service",
		url: "",
		icon: <MdHome size={18} />,
	},
];

export const LmsItems = [
	{
		title: "Dashboard",
		url: "lms/dashboard",
		icon: <MdOutlineLibraryBooks size={18} />,
	},
	{
		title: "My Profile",
		url: "lms/my-profile",
		icon: <MdOutlineAccountCircle size={18} />,
	},
	{
		title: "Leave Request",
		url: "lms/leave-request",
		icon: <MdOutlineCalendarToday size={16} />,
	},
	{
		title: "WFH Request",
		url: "lms/work-home-request",
		icon: <MdOutlineAddHomeWork size={18} />,
	},
	{
		title: "Compensation Request",
		url: "lms/compensation-request",
		icon: <MdOutlineMonetizationOn size={18} />,
	},
	{
		title: "Redeem Request",
		url: "lms/reedem-request",
		icon: <BsCashCoin size={18} />,
	},
	{
		title: "Holiday Calender",
		url: "lms/holiday-calendar",
		icon: <MdOutlineEvent size={18} />,
	},
	{
		title: "Leave Policy",
		url: "lms/leave-policy",
		icon: <MdOutlinePolicy size={18} />,
	},
	{
		title: "Employee Self Service",
		url: "",
		icon: <MdOutlineHome size={20} />,
	},
];

export const LmsItemsTrai = [
	{
		title: "Dashboard",
		url: "lms/dashboard",
		icon: <MdOutlineLibraryBooks size={18} />,
	},
	{
		title: "My Profile",
		url: "lms/my-profile",
		icon: <MdOutlineAccountCircle size={18} />,
	},
	{
		title: "Leave Request",
		url: "lms/leave-request",
		icon: <MdOutlineCalendarToday size={16} />,
	},
	{
		title: "Compensation Request",
		url: "lms/compensation-request",
		icon: <MdOutlineMonetizationOn size={18} />,
	},
	{
		title: "Redeem Request",
		url: "lms/reedem-request",
		icon: <BsCashCoin size={18} />,
	},
	{
		title: "Holiday Calender",
		url: "lms/holiday-calendar",
		icon: <MdOutlineEvent size={18} />,
	},
	{
		title: "Leave Policy",
		url: "lms/leave-policy",
		icon: <MdOutlinePolicy size={18} />,
	},
	{
		title: "Employee Self Service",
		url: "",
		icon: <MdOutlineHome size={20} />,
	},
];

export const LmsItemsForManager = [
	{
		title: "Dashboard",
		url: "lms/dashboard",
		icon: <MdOutlineLibraryBooks size={18} />,
	},
	{
		title: "My Profile",
		url: "lms/my-profile",
		icon: <MdOutlineAccountCircle size={18} />,
	},
	{
		title: "Leave Request",
		url: "lms/leave-request",
		icon: <MdOutlineCalendarToday size={16} />,
	},
	{
		title: "WFH Request",
		url: "lms/work-home-request",
		icon: <MdOutlineAddHomeWork size={18} />,
	},
	{
		title: "Employee Leave History",
		url: "lms/leave-history",
		icon: <FaHistory size={16} />,
	},
	{
		title: "Employee WFH History",
		url: "lms/wfh-history",
		icon: <MdNewspaper size={18} />,
	},
	{
		title: "Compensation Request",
		url: "lms/compensation-request",
		icon: <MdOutlineMonetizationOn size={18} />,
	},
	{
		title: "Redeem Request",
		url: "lms/reedem-request",
		icon: <BsCashCoin size={18} />,
	},
	{
		title: "Holiday Calender",
		url: "lms/holiday-calendar",
		icon: <MdOutlineEvent size={18} />,
	},
	{
		title: "Leave Policy",
		url: "lms/leave-policy",
		icon: <MdOutlinePolicy size={18} />,
	},
	{
		title: "Employee Self Service",
		url: "",
		icon: <MdOutlineHome size={20} />,
	},
];

export const TimesheetWorkModel = [
	{ label: "Select", value: "" },
	{ label: "Work From Home", value: "Work From Home" },
	{ label: "Work From Office", value: "Work From Office" },
];

export const LmsCeoItems = [
	{
		title: "Dashboard",
		url: "lms/dashboard",
		icon: <MdOutlineLibraryBooks size={18} />,
	},
	{
		title: "Holiday Calender",
		url: "lms/holiday-calendar",
		icon: <MdOutlineEvent size={18} />,
	},
	{
		title: "Leave Policy",
		url: "lms/leave-policy",
		icon: <MdOutlinePolicy size={18} />,
	},
	{
		title: "Employee Self Service",
		url: "",
		icon: <MdOutlineHome size={20} />,
	},
];

export const LmsAdminItems = [
	{
		title: "Dashboard",
		url: "lms/dashboard",
		icon: <MdOutlineLibraryBooks size={18} />,
	},
	{
		title: "Create User",
		url: "lms/create-user",
		icon: <MdOutlineAccountCircle size={18} />,
	},
	{
		title: "Modify User",
		url: "lms/modify-user",
		icon: <AiOutlineEdit size={16} />,
	},
	{
		title: "Delete User",
		url: "lms/delete-user",
		icon: <MdOutlineDelete size={18} />,
	},
	{
		title: "Holiday Calender",
		url: "lms/holiday-calendar",
		icon: <MdOutlineEvent size={18} />,
	},
	{
		title: "Leave Policy",
		url: "lms/leave-policy",
		icon: <MdOutlinePolicy size={18} />,
	},
	{
		title: "Employee Self Service",
		url: "",
		icon: <MdOutlineHome size={20} />,
	},
];

export const LmsItemsForHr = [
	{
		title: "Dashboard",
		url: "lms/dashboard",
		icon: <MdOutlineLibraryBooks size={18} />,
	},
	{
		title: "Leave Request",
		url: "lms/leave-request",
		icon: <MdOutlineCalendarToday size={16} />,
	},
	{
		title: "WFH Request",
		url: "lms/work-home-request",
		icon: <MdOutlineAddHomeWork size={18} />,
	},
	{
		title: "Employee Leave History",
		url: "lms/leave-history",
		icon: <FaHistory size={16} />,
	},
	{
		title: "Employee WFH History",
		url: "lms/wfh-history",
		icon: <MdNewspaper size={18} />,
	},
	{
		title: "Rejected Applications",
		url: "lms/rejected-applications",
		icon: <MdCancelPresentation size={18} />,
	},
	{
		title: "Redeem Request",
		url: "lms/reedem-request",
		icon: <BsCashCoin size={18} />,
	},
	{
		title: "Holiday Calender",
		url: "lms/holiday-calendar",
		icon: <MdOutlineEvent size={18} />,
	},
	{
		title: "Leave Policy",
		url: "lms/leave-policy",
		icon: <MdOutlinePolicy size={18} />,
	},
	{
		title: "Employee Self Service",
		url: "",
		icon: <MdOutlineHome size={20} />,
	},
];

export const MyDetailsItems = [
	{
		title: "My Details",
		url: "mydetails/user-profile",
		icon: <MdOutlinePersonOutline size={18} />,
	},
	{
		title: "My Skill Set",
		url: "mydetails/user-skill-set",
		icon: <MdOutlinePsychology size={18} />,
	},
	{
		title: "Employee Self Service",
		url: "",
		icon: <MdOutlineLibraryBooks size={20} />,
	},
];

export const MyDetailsItemsForAdmin = [
	{
		title: "My Details",
		url: "mydetails/user-profile",
		icon: <MdOutlinePersonOutline size={18} />,
	},
	{
		title: "Employee Self Service",
		url: "",
		icon: <MdOutlineHome size={18} />,
	},
];

export const MyDetailsItemsForHR = [
	{
		title: "My Details",
		url: "mydetails/user-profile",
		icon: <MdOutlinePersonOutline size={18} />,
	},
	{
		title: "Employee Skills",
		url: "mydetails/user-skills",
		icon: <MdOutlinePsychology size={18} />,
	},
	{
		title: "Primary Skills",
		url: "mydetails/user-primary-skills",
		icon: <MdOutlineMenuBook size={18} />,
	},
	{
		title: "Secondary Skills",
		url: "mydetails/user-secondary-skills",
		icon: <AiOutlineSafetyCertificate size={18} />,
	},
	{
		title: "Employee Self Service",
		url: "",
		icon: <MdOutlineHome size={20} />,
	},
];

export const MyDetailsItemsForCEO = [
	{
		title: "My Details",
		url: "mydetails/user-profile",
		icon: <MdPerson size={18} />,
	},
	{
		title: "Primary Skills",
		url: "mydetails/user-primary-skills",
		icon: <MdOutlinePsychology size={18} />,
	},
	{
		title: "Secondary Skills",
		url: "mydetails/user-secondary-skills",
		icon: <AiOutlineSafetyCertificate size={18} />,
	},
	{
		title: "Employee Self Service",
		url: "",
		icon: <MdOutlineHome size={20} />,
	},
];

export const HelpDeskItems = [
	{
		title: "Help Desk",
		url: "helpdesk/dashboard",
		icon: <FaHandsHelping />,
	},

	{
		title: "Tickets",
		url: "helpdesk/ticket",
		icon: <MdAirplaneTicket />,
	},

	{
		title: "Employee Self Service",
		url: "",
		icon: <MdOutlineHome size={20} />,
	},
];

export const HelpDeskItemsTwo = [
	{
		title: "Help Desk",
		url: "helpdesk/dashboard",
		icon: <FaHandsHelping />,
	},

	{
		title: "Tickets",
		url: "helpdesk/ticket",
		icon: <MdAirplaneTicket />,
	},
	{
		title: "Open Tickets",
		url: "helpdesk/open-ticket",
		icon: <MdAirplaneTicket />,
	},

	{
		title: "Closed Tickets",
		url: "helpdesk/closed-ticket",
		icon: <MdClosedCaptionDisabled />,
	},

	{
		title: "Employee Self Service",
		url: "",
		icon: <MdOutlineHome size={20} />,
	},
];

export const HelpDeskItemsForAdminCeo = [
	{
		title: "Help Desk",
		url: "helpdesk/dashboard",
		icon: <FaHandsHelping />,
	},

	{
		title: "Employee Self Service",
		url: "",
		icon: <MdOutlineHome size={20} />,
	},
];

export const HelpDeskItemsHR = [
	{
		title: "Help Desk",
		url: "helpdesk/dashboard",
		icon: <FaHandsHelping />,
	},

	{
		title: "Open Tickets",
		url: "helpdesk/openticket",
		icon: <MdAirplaneTicket />,
	},

	{
		title: "Closed Tickets",
		url: "helpdesk/closedticket",
		icon: <MdClosedCaptionDisabled />,
	},

	{
		title: "Employee Self Service",
		url: "",
		icon: <MdOutlineHome size={20} />,
	},
];

export const MyTeamItems = [
	{
		title: "Organization Chart",
		url: "myteam/user-team",
		icon: <MdOutlineAccountCircle />,
	},
	{
		title: "Employee Self Service",
		url: "",
		icon: <MdOutlineHome size={20} />,
	},
];

export const OurOrganization = [
	{
		title: "Our Organization",
		url: "our-organization/our-organization-chart",
		icon: <MdOutlineAccountCircle />,
	},
	{
		title: "Employee Self Service",
		url: "",
		icon: <MdOutlineHome size={20} />,
	},
];

export const ConferenceRoomItems = [
	{
		title: "Dashboard",
		url: "conference-room/dashboard",
		icon: <MdOutlineLibraryBooks size={18} />,
	},
	{
		title: "History",
		url: "conference-room/history",
		icon: <MdOutlineLibraryBooks size={18} />,
	},
	{
		title: "Employee Self Service",
		url: "",
		icon: <MdOutlineHome size={20} />,
	},
];

export const EmployeeDetailsMang = [
	{
		title: "Dashboard",
		url: "employee-details",
		icon: <MdOutlineLibraryBooks size={18} />,
	},

	{
		title: "Employee Self Service",
		url: "",
		icon: <MdHome size={18} />,
	},
];

export const BarChartData = [
	{ x_axis: "21-Jul-2023", y_axis: 10 },
	{ x_axis: "22-Jul-2023", y_axis: 20 },
];

export const PieChartData = [
	{ data: "Category 1", value: 30 },
	{ data: "Category 2", value: 50 },
	{ data: "Category 3", value: 20 },
	{ data: "Category 4", value: 10 },
];

export const CardData = [
	{
		img: Payroll,
		title: "Payroll",
		description:
			"Run payroll in a few clicks and get payslips,HR policy,and form16.",
		button: "Payroll",
		url: "payroll/dashboard",
	},
	{
		img: LMS,
		title: "LMS",
		description: "Easily apply, manage, and keep track of your leave balances.",
		button: "LMS",
		url: "lms/dashboard",
	},
	// {
	// 	img: LMS,
	// 	title: "Our Organization",
	// 	description: "Easily apply, manage, and keep track of your leave balances.",
	// 	button: "LMS",
	// 	url: "/our-organization/our-organization-chart",
	// },
	{
		img: timesheet,
		title: "Timesheet",
		description:
			"Manage all your work from start to end. Customize your workflows.",
		button: "Timesheet",
		url: "/timesheet/dashboard",
	},

	{
		img: team,
		title: "My Team",
		description: "Explore your team chart and Discover current team members.",
		button: "Team",
		url: "/myteam/user-team",
	},

	{
		img: EmpDetailInfo,
		title: "Employee Details",
		description:
			"Manage employee information efficiently with the employee details dashboard.",
		button: "Emp Details",
		url: "employee-details",
	},

	{
		img: details,
		title: "My Details",
		description: "Update your profile and keep information upto date.",
		button: "Details",
		url: "mydetails/user-profile",
	},

	{
		img: support,
		title: "Help Desk",
		description: "Manage your support cases effectively with the help desk.",
		button: "Support",
		url: "helpdesk/dashboard",
	},

	{
		img: training,
		title: "Training",
		description:
			"Enroll in the training courses and develop your strategic thinking.",
		button: "Training",
		url: "training",
	},
	// {
	// 	img: training,
	// 	title: "Conference Room",
	// 	description:
	// 		"Enroll in the training courses and develop your strategic thinking.",
	// 	button: "Book Now",
	// 	url: "conference-room/dashboard",
	// },

	{
		img: admin,
		title: "Admin Portal",
		description: "Manage the configuration for your organization.",
		button: "Admin",
		url: "/admin/policy-update",
	},
];

// export const TeamData = {
// 	Manager: {
// 		label: "Manager",
// 		Identity: "name",
// 		image: Manager,
// 		children: [
// 			{
// 				label: "Employee",
// 				Identity: "name",
// 				image: Trainer,
// 				children: [
// 					{ label: "Trainee", Identity: "name", image: Women },
// 					{ label: "Trainee", Identity: "name", image: Men },
// 				],
// 			},
// 			{
// 				label: "Employee",
// 				Identity: "name",
// 				image: Trainer,
// 				children: [
// 					{ label: "Trainee", Identity: "name", image: Men },
// 					{ label: "Trainee", Identity: "name", image: Women },
// 				],
// 			},
// 			{ label: "Employee", Identity: "name", image: Men },
// 			{ label: "Employee", Identity: "name", image: Women },
// 		],
// 	},
// };

export const UserProfileDetails = [
	{
		name: "Johnson",
		email: "johnson@gmail.com",
		designation: "Software Developer",
		dob: "Aug 10 1990",
		doj: "June 20 2020",
		image: Men,
	},
];

export const LeaveRequestOptionalData = [
	{ value: "2023-06-29", label: "Happy BirthDay" },
	{ value: "2023-08-29", label: "Onam" },
];

export const LmsCardsDetails = [
	{
		title: "Total Leaves Applied",
		icons: <MdStackedBarChart />,
		number: "2.5",
		color: "bg-cardOneBg",
	},
	{
		title: "Total LOP Days",
		icons: <MdStackedBarChart />,
		number: "2.5",
		color: "bg-cardTwoBg",
	},
	{
		title: "Sick Leave Pending",
		icons: <MdStackedBarChart />,
		number: "2.5",
		color: "bg-cardThreeBg",
	},
	{
		title: "Casual Leave Pending",
		icons: <MdStackedBarChart />,
		number: "2.5",
		color: "bg-cardFourBg",
	},
];

export const cardIcons = [
	<MdStackedBarChart />,
	<MdStackedBarChart />,
	<MdStackedBarChart />,
	<MdStackedBarChart />,
];

// LMS DASHBOARD DATA

export const lmsTableHead = [
	"Leave Type",
	"Applied date",
	"Report TO Status",
	"Hr Status",
	"Action",
];

export const wfhTableHead = [
	"Applied date",
	"Number of Days",
	"Report TO Status",
	"Hr Status",
	"Action",
];

export const wfhTableHeadForManager = [
	"Name",
	"Applied date",
	"Report TO Status",
	"Hr Status",
	"Action",
];

export const lmsTableHeadForManager = [
	"Name",
	"Leave Type",
	"Applied date",
	"Report TO Status",
	"Hr Status",
	"Action",
];

export const lmsAppliedLeaveHead = [
	"Type",
	"No of Days",
	"Start Date",
	"End Date",
	"Description",
];

// export const popupTableHead = ["Name", "Email Id", "Leave Type"];

// export const lmsTableBody = [
// 	["sick", "28/6/2023", "manager"],
// 	["sick", "28/6/2023", "manager"],
// 	["sick", "28/6/2023", "manager"],
// 	["sick", "28/6/2023", "manager"],
// ];

export const lmsTableBody = [
	{
		id: 1,
		leaveType: "sick",
		date: "02-09-2023",
		reportTostatus: "Approved",
		hrStatus: "Approved",
		leaveDecision: "ok",
		name: "User",
		email: "user@gmail.com",
		leaveType: "sick",
	},
	{
		id: 2,
		leaveType: "sick",
		date: "02-09-2023",
		reportTostatus: "not Approved",
		hrStatus: "not Approved",
		leaveDecision: "no",
		name: "User Two",
		email: "usertwo@gmail.com",
		leaveType: "casual",
	},
	{
		id: 3,
		leaveType: "sick",
		date: "02-09-2023",
		reportTostatus: "Approved",
		hrStatus: "Approved",
		leaveDecision: "ok",
		name: "User Three",
		email: "userthree@gmail.com",
		leaveType: "sick",
	},
];

export const timesheetTableHead = [
	"Project",
	"Manager",
	"Date",
	"Start Time",
	"End Time",
	"Logged Hours",
	"Non Productive",
	"Status",
	"Edit",
];

export const timesheetTableHeadHr = [
	"Project",
	"Manager",
	"Date",
	// "Start Time",
	// "End Time",
	"Logged Hrs",
	"Work Mode",
	// "Non Productive",
	"Status",
	"View",
	"Edit",
];

export const timesheetTableHeadEmpAndManager = [
	"Project",
	"Manager",
	"Date",
	// "Start Time",
	// "End Time",
	"Logged Hrs",
	"Work Mode",
	// "Non Productive",
	"Status",
	"View",
];

export const employeeTableHead = [
	"Emp Id",
	"Employee Name",
	"E-Mail Address",
	"Role",
	"Action",
];
export const timesheetTableBody = [
	{
		project: "Project A",
		manager: "aaaa",
		date: "05-04-2023",
		starttime: "10:30 AM",
		endtime: "06:30 PM",
		loggedhours: "6",
		nonproductive: "5",
		status: "working on",
	},

	{
		project: "Project B",
		manager: "bbb",
		date: "10-04-2023",
		starttime: "10:30 AM",
		endtime: "06:30 PM",
		loggedhours: "7",
		nonproductive: "2",
		status: "working on",
	},
	{
		project: "Project c",
		manager: "ccccc",
		date: "20-04-2023",
		starttime: "10:30 AM",
		endtime: "06:30 PM",
		loggedhours: "7",
		nonproductive: "1",
		status: "completed",
	},
];

export const EmployeeRecord = [
	{ value: 10, label: "SHOW 10 RECORD" },
	{ value: 20, label: "SHOW 20" },
	{ value: 30, label: "SHOW 30" },
	{ value: 1000, label: "SHOW All" },
];

export const employeeTableBody = [
	"empid : 1",
	"employeename : Tamil",
	"emailaddress : tamil@gmail.com",
	"role : manager",
	"action : edit",
];

export const AdminItems = [
	{
		title: "Policy Update",
		url: "admin/policy-update",
		icon: <MdPolicy size={20} />,
	},
	{
		title: "Training Materials",
		url: "admin/training-materials-upload",
		icon: <MdOutlineLibraryBooks size={20} />,
	},
	{
		title: "Add Holiday",
		url: "admin/add-holiday",
		icon: <MdHolidayVillage size={20} />,
	},
	{
		title: "Modify Holiday",
		url: "admin/modifiedholiday",
		icon: <MdBuild size={20} />,
	},
	{
		title: "Announcement",
		url: "admin/announcement",
		icon: <MdAnnouncement size={20} />,
	},

	{
		title: "Employee Self Service",
		url: "",
		icon: <MdOutlineHome size={20} />,
	},
];

export const lmsHolidayCalendarHead = ["S.No", "Date", "Day", "Type"];

export const empDetailsTabHeadInfo = [
	"Name",
	"Primary Phone Number",
	"Secondary Phone Number",
	"Permanent Address",
	"Temporary Address",
];

export const lmsLeaveHistoryHead = [
	"ID",
	"Employee Name",
	"Leaves Applied",
	"Time Sheet Deductions",
	"Leaves Pending",
	"Action",
];

export const wfhHistoryHead = [
	"ID",
	"Employee Name",
	"Applied",
	"WFH Pending",
	"Action",
];

export const lmsLeaveHistoryHeadForManager = [
	"ID",
	"Employee Name",
	"Leaves Applied",
	"Leaves Pending",
	"Action",
];

export const lmsLeaveHistoryBody = [
	{
		id: 1,
		name: "saravanan",
		leave: "2",
		detuction: "10",
		lpend: "10",
		spending: "10",
		cpending: "11",
		lop: "10",
		availH: "1",
		Capplied: "10",
		reedem: "10",
	},
];

export const lmsRejectedApplicationsHead = [
	"ID",
	"Name",
	"Leave Type",
	"Applied date",
	"Manager Status",
	"Action",
];

export const lmsRejectedApplicationsBody = [
	{
		id: 1,
		name: "saravanan",
		leave: "sick",
		date: "July 11, 2023",
		status: "Rejected",
	},
];

export const lmsUpdateUserData = [
	{
		id: 1,
		name: "saravanan",
		sleave: 9,
		cleave: 9,
		oleave: 9,
		lop: 10,
	},
];

// LMS ADMIN CREATEUSER

export const lmsUserRole = [
	{
		value: "",
		label: "Select",
	},
	{
		value: "HR",
		label: "HR",
	},
	{
		value: "MANAGER",
		label: "MANAGER",
	},
	{
		value: "EMPLOYEE",
		label: "EMPLOYEE",
	},
	{
		value: "INTERN",
		label: "INTERN",
	},
	{
		value: "TRAINEE",
		label: "TRAINEE",
	},
	{
		value: "CONSULTANT",
		label: "CONSULTANT",
	},
];

export const lmsUserGender = [
	{
		value: "",
		label: "Select",
	},
	{
		value: "male",
		label: "Male",
	},
	{
		value: "female",
		label: "Female",
	},
	{
		value: "other",
		label: "Other",
	},
];

// LMS ADMIN MODIFY USER

export const lmsModifyUserHead = [
	"ID",
	"Employee Name",
	"E-Mail Address",
	"Role",
	"Action",
];

// LMS ADMIN DELETE USER

export const lmsDeleteUserHead = [
	"ID",
	"Employee Name",
	"E-Mail Address",
	"Role",
	"Action",
];

// Pagination Drop Down

export const dropDownPagination = [
	{ value: 10, label: "Show Top 10" },
	{ value: 25, label: "Show Top 25" },
	{ value: 50, label: "Show Top 50" },
	{ value: 1000, label: "Show All" },
];

//PAYROLL

// PAYROLL FOR EMPLOYEE

export const payrollItems = [
	{
		title: "Dashboard",
		url: "payroll/dashboard",
		icon: <MdOutlineLibraryBooks size={18} />,
	},
	{
		title: "Pay Slips",
		url: "payroll/pay-slips",
		icon: <MdOutlineMonetizationOn size={18} />,
	},
	{
		title: "Form 16",
		url: "payroll/form-16",
		icon: <AiOutlineForm size={18} />,
	},
	{
		title: "HR Policy",
		url: "payroll/hr-policy",
		icon: <MdOutlinePolicy size={18} />,
	},
	{
		title: "Employee Self Service",
		url: "",
		icon: <MdOutlineHome size={18} />,
	},
];

export const payrollItemsForHR = [
	{
		title: "Dashboard",
		url: "payroll/dashboard",
		icon: <MdOutlineLibraryBooks size={18} />,
	},
	{
		title: "Pay Slip Updation",
		url: "payroll/pay-slip-updation",
		icon: <FaWpforms size={18} />,
	},
	{
		title: "Form 16 Updation",
		url: "payroll/form-16-updation",
		icon: <RxUpdate size={18} />,
	},
	{
		title: "Pay Slip Generation",
		url: "payroll/pay-slip-generation",
		icon: <TbBrandCashapp size={18} />,
	},
	{
		title: "Pay Slips",
		url: "payroll/pay-slips",
		icon: <MdOutlineMonetizationOn size={18} />,
	},
	{
		title: "Form 16",
		url: "payroll/form-16",
		icon: <AiOutlineForm size={18} />,
	},
	{
		title: "HR Policy",
		url: "payroll/hr-policy",
		icon: <MdOutlinePolicy size={18} />,
	},
	{
		title: "Employee Self Service",
		url: "",
		icon: <MdOutlineHome size={18} />,
	},
];

export const payrollItemsForManager = [
	{
		title: "Dashboard",
		url: "payroll/dashboard",
		icon: <MdOutlineLibraryBooks size={18} />,
	},
	{
		title: "Pay Slips",
		url: "payroll/pay-slips",
		icon: <MdOutlineMonetizationOn size={18} />,
	},
	{
		title: "Form 16",
		url: "payroll/form-16",
		icon: <AiOutlineForm size={18} />,
	},
	{
		title: "HR Policy",
		url: "payroll/hr-policy",
		icon: <MdOutlinePolicy size={18} />,
	},
	{
		title: "Employee Self Service",
		url: "",
		icon: <MdOutlineHome size={18} />,
	},
];

export const payrollItemsForCeo = [
	{
		title: "Dashboard",
		url: "payroll/dashboard",
		icon: <MdOutlineLibraryBooks size={18} />,
	},
	{
		title: "HR Policy",
		url: "payroll/hr-policy",
		icon: <MdOutlinePolicy size={18} />,
	},
	{
		title: "Employee Self Service",
		url: "",
		icon: <MdOutlineHome size={18} />,
	},
];

export const filterDateMonth = [
	{ value: "none", label: "Select" },
	{
		value: 1,
		label: "Jan",
	},
	{
		value: 2,
		label: "Feb",
	},
	{
		value: 3,
		label: "Mar",
	},
	{
		value: 4,
		label: "Apr",
	},
	{
		value: 5,
		label: "May",
	},
	{
		value: 6,
		label: "Jun",
	},
	{
		value: 7,
		label: "Jul",
	},
	{
		value: 8,
		label: "Aug",
	},
	{
		value: 9,
		label: "Sep",
	},
	{
		value: 10,
		label: "Oct",
	},
	{
		value: 11,
		label: "Nov",
	},
	{
		value: 12,
		label: "Dec",
	},
];

export const appliedDataLms = [
	"Name",
	"Type",
	"No Of Days",
	"Description",
	"Start Date",
	"End Date",
	"Status",
];

export const PayslipFormHeadData = [
	"Email",
	"Employee No",
	"Designation",
	"Current CTC",
	"Action",
];

export const PayslipFormBodyData = [
	{
		id: 1,
		name: "saravanan",
		no: "10",
		designation: "Employee",
		ctc: "100000",
	},
	{
		id: 2,
		name: "Roman",
		no: "10",
		designation: "Employee",
		ctc: "100000",
	},
	{
		id: 3,
		name: "Vijay",
		no: "10",
		designation: "Employee",
		ctc: "100000",
	},
	{
		id: 5,
		name: "Elon",
		no: "10",
		designation: "Employee",
		ctc: "100000",
	},
	{
		id: 6,
		name: "Elon",
		no: "10",
		designation: "Employee",
		ctc: "100000",
	},
	{
		id: 7,
		name: "Elon",
		no: "10",
		designation: "Employee",
		ctc: "100000",
	},
	{
		id: 8,
		name: "Vijay",
		no: "10",
		designation: "Employee",
		ctc: "100000",
	},
	{
		id: 9,
		name: "Vijay",
		no: "10",
		designation: "Employee",
		ctc: "100000",
	},
	{
		id: 10,
		name: "saravanan",
		no: "10",
		designation: "Employee",
		ctc: "100000",
	},
];
// Admin Portal

export const AdminListHead = [
	"Id",
	"Holiday Name",
	"Holiday Type",
	"Holiday Date",
];

// My Details

export const MyDetailsAsset = [
	{
		value: "",
		label: "Select",
	},
	{
		value: "None",
		label: "None",
	},
	{
		value: "company laptop",
		label: "Company Laptop",
	},
	{
		value: "personal laptop",
		label: "Personal Laptop",
	},
];

export const employeeSkillsTableHeader = [
	"Employee ID",
	"Employee Name",
	"Skills details",
	"View",
];

export const employeeSkillsPopupTableHeader = [
	"Employee Name",
	"Primary Skill",
	"Secondary Skill",
];

export const primarySkillsTableHeader = ["Employee Skills", "Primary Skills"];

export const primarySkillsPopupTableHeader = ["ID", "Employee Name"];

// Help Desk

export const helpDeskTickes = [
	"S.no",
	"Employee Name",
	"Category",
	"Description",
	"Status",
];

export const helpDeskOpenTickes = [
	"ID",
	"Employee Name",
	"Category",
	"Description",
	"Status",
	"Action",
];

export const optionstwottt = [
	{ label: "New York", value: "newyork" },
	{ label: "Rome", value: "rome" },
	{ label: "London", value: "london" },
];

export const optionstwotttt = [
	{ label: "Select User", value: "" },
	{ label: "New York", value: "newyork" },
	{ label: "Rome", value: "rome" },
	{ label: "London", value: "london" },
];
